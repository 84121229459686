import Cmp from './Page'
import { graphql } from 'gatsby'

export const pageQuery = graphql`
  query genericPageQuery($prismicId: ID!) {
    prismicPage(prismicId: { eq: $prismicId }) {
      id
      uid
      prismicId
      data {
        title {
          text
        }
        footer_type
        bg_color
        submenu
        has_subhero
        body {
          __typename
          ... on PrismicPageBodyServices {
            primary {
              headline {
                text
              }
              subheadline {
                text
              }
              link_text {
                text
              }
              link {
                url
              }
            }
            items {
              headline {
                text
              }
              text {
                html
              }
              img {
                url
                alt
              }
              link_text {
                text
              }
              link {
                url
              }
              img_tag {
                text
              }
            }
          }
          ... on PrismicPageBodyMoreWork {
            primary {
              headline {
                text
              }
              subheadline {
                text
              }
              link_text {
                text
              }
              link {
                url
              }
            }
            items {
              project {
                url
                document {
                  __typename
                  ... on PrismicProject {
                    data {
                      title {
                        html
                        text
                      }
                      headline {
                        text
                      }
                      project_color
                      case_study
                      project_image {
                        url
                        alt
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicPageBodyTwoColQuote {
            primary {
              headline {
                text
              }
              quote {
                html
                text
              }
              author {
                text
              }
              job_title {
                text
              }
              author_img {
                url
                alt
              }
            }
          }
          ... on PrismicPageBodyHero {
            primary {
              headline {
                text
              }
              subheadline {
                text
              }
              img {
                url
                alt
              }
            }
          }
          ... on PrismicPageBodySubpageHero {
            primary {
              headline {
                text
              }
              subheadline {
                text
              }
              img {
                url
                alt
              }
              type
              design_sprint
            }
          }
          ... on PrismicPageBodyTitleLeadTextImage {
            primary {
              headline {
                text
              }
              lead {
                html
              }
              text {
                html
              }
              img {
                url
                alt
              }
            }
          }
          ... on PrismicPageBodyStaffList {
            primary {
              headline {
                text
              }
              subheadline {
                text
              }
            }
            items {
              staff_items {
                document {
                  __typename
                  ... on PrismicStaff {
                    data {
                      name {
                        text
                      }
                      job_title {
                        text
                      }
                      email {
                        text
                      }
                      img {
                        url
                        alt
                      }
                      info {
                        html
                      }
                      linkedin {
                        text
                      }
                      instagram {
                        text
                      }
                      link {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicPageBodyLogoList {
            primary {
              headline {
                text
              }
              subtitle {
                text
              }
            }
            items {
              img {
                url
                alt
              }
              link {
                url
              }
            }
          }
          ... on PrismicPageBodyServiceSteps {
            primary {
              headline {
                text
              }
              lead {
                html
              }
            }
            items {
              step_text {
                html
              }
              step_title {
                text
              }
            }
          }
          ... on PrismicPageBodyFootnotes {
            items {
              link {
                url
              }
              link_text {
                text
              }
            }
          }
          ... on PrismicPageBodyDropUsALine {
            primary {
              headline {
                text
              }
              link {
                url
              }
              link_text {
                text
              }
            }
          }
          ... on PrismicPageBodySendUsAMessage {
            primary {
              headline {
                text
              }
              text {
                html
              }
            }
          }
          ... on PrismicPageBodyGallery {
            primary {
              headline {
                text
              }
              subtitle {
                text
              }
              type
            }
            items {
              img {
                url
                alt
              }
            }
          }
          ... on PrismicPageBodyOpenPositions {
            primary {
              headline {
                text
              }
              text {
                html
              }
            }
            items {
              link_title {
                text
              }
              link {
                url
              }
            }
          }
          ... on PrismicPageBodyResumeForm {
            primary {
              headline {
                text
              }
              text {
                html
              }
            }
          }
          ... on PrismicPageBodyArticleBody {
            primary {
              texti {
                html
              }
            }
          }
        }
      }
    }
  }
`

export default Cmp
