import React from 'react'
import { mergePrismicPreviewData } from 'gatsby-source-prismic'
import Main from '../../layouts/Main'
import { ScrollSectionProvider } from '../../context/ScrollContext'
import { sliceMapping } from '../../utils/sliceMapping'
import { Transition } from '../../components/'
import makeSlices from '../../prismic/Slices'

const Slices = makeSlices(sliceMapping)

const IS_BROWSER = typeof window !== 'undefined'

const Page = ({ data: staticData }) => {
  const sections = []

  const previewData = IS_BROWSER && window.__PRISMIC_PREVIEW_DATA__
  const data = mergePrismicPreviewData({ staticData, previewData })

  const {
    prismicPage: {
      data: pageData
    }
  } = data

  const isRedFooter = pageData.footer_type === 'red'
  const isWhiteBg = pageData.bg_color === 'white'
  const darkTheme = pageData.bg_color === 'dark blue'
  const hasSubNav = pageData.submenu === 'true'
  const hasSubhero = pageData.has_subhero === 'true'

  return (
    <ScrollSectionProvider value={sections} >
      <Main
        redFooter={isRedFooter}
        whiteBG={isWhiteBg}
        hasSubNav={hasSubNav}
        extraTopPadding={!hasSubhero}
        darkTheme={darkTheme}
      >
        <Transition>
          <Slices
            body={pageData.body}
            color={darkTheme}
          />
        </Transition>
      </Main>
    </ScrollSectionProvider>
  )
}

export default Page
